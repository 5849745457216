<template>
  <div class="applyEquipment">
    <div class="contant">
      <div class="setinfo">
        <div class="title">基本信息</div>
        <div class="item">
          <div class="tit">门店名称<span>*</span></div>
          <div class="inp"  v-if="isSelect" @click="storeNameClick">
            {{subdata.store_name?subdata.store_name:storeNameplaceholder}}
          </div>
          <div class="inp" v-else>
            {{subdata.store_name?subdata.store_name:storeNameplaceholder}}
          </div>
        </div>
        <!-- <div class="item">
          <div class="tit">设备负责人<span>*</span></div>
          <div class="inp">
            <input type="text" v-model="subdata.person" placeholder="请输入联系人" />
          </div>
        </div>
        <div class="item">
          <div class="tit">设备手机号<span>*</span></div>
          <div class="inp">
            <input type="text" v-model="subdata.mobile" placeholder="请输入手机号" />
          </div>
        </div> -->
        <div class="item">
          <div class="tit">购买类型<span>*</span></div>
          <div class="inp" @click="buyTypeClick">
            {{subdata.type_text?subdata.type_text:"请选择类型"}}
          </div>
        </div>
        <div class="item">
          <div class="tit">设备类型<span>*</span></div>
          <div class="inp" @click="equipTypeClick">
            {{subdata.device_type_text?subdata.device_type_text:"请选择类型"}}
          </div>
        </div>
      </div>
      <div class="setinfo">
        <div class="title">设备信息</div>
        <div class="item">
          <div class="tit">设备数量<span>*</span></div>
          <div class="inp">
            <input type="text" v-model="subdata.num" placeholder="请添加数量" />
          </div>
        </div>
        <div class="item">
          <div class="tit">有线手柄数量</div>
          <div class="inp">
            <input
              type="text"
              v-model="subdata.line_handle_num"
              placeholder="请添加数量"
            />
          </div>
        </div>
        <div class="item">
          <div class="tit">无线手柄数量</div>
          <div class="inp">
            <input
              type="text"
              v-model="subdata.blue_handle_num"
              placeholder="请添加数量"
            />
          </div>
        </div>
        <div class="item">
          <div class="tit">易拉宝数量</div>
          <div class="inp">
            <input type="text" v-model="subdata.elabao_num" placeholder="请添加数量" />
          </div>
        </div>
        <div class="item">
          <div class="tit">立式指引牌数量</div>
          <div class="inp">
            <input type="text" v-model="subdata.lipai_num" placeholder="请添加数量" />
          </div>
        </div>
        <div class="item">
          <div class="tit">无线手柄支架数量</div>
          <div class="inp">
            <input
              type="text"
              v-model="subdata.handle_stand_num"
              placeholder="请添加数量"
            />
          </div>
        </div>
        <div class="item">
          <div class="text">
            <div class="top">
              <div class="tit">现场图片</div>
            </div>
            <div class="tip">图片大小不超过20M</div>
          </div>
          <div class="pic">
            <van-uploader
              v-model="storePic"
              :max-size="20 * 1024 * 1024"
              :max-count="1"
              @oversize="onOversize"
              :after-read="afterRead"
            />
          </div>
        </div>
      </div>
      <div class="remark">
        <div class="tit">备注信息</div>
        <div class="textarea">
          <textarea
            rows="5"
            v-model="subdata.remarks"
            placeholder="请输入备注"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="btns">
      <button type="button" @click="subClick">提交</button>
    </div>

    <van-popup v-model:show="storeNamePup" position="bottom" round>
      <van-picker
        title="选择门店"
        :columns="storeNameList"
        @confirm="storeNameConfirm"
        @cancel="storeNameCancel"
      />
    </van-popup>

    <van-popup v-model:show="buyTypePup" position="bottom" round>
      <van-picker
        title="选择购买类型"
        :columns="buyTypeList"
        @confirm="buyTypePupConfirm"
        @cancel="buyTypePupCancel"
        :default-index="buyTypeDef"
      />
    </van-popup>

    <van-popup v-model:show="equipTypePup" position="bottom" round>
      <van-picker
        title="选择设备类型"
        :columns="deviceTypeList"
        @confirm="equipTypeConfirm"
        @cancel="equipTypeCancel"
        :default-index="deviceTypeDef"
      />
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import API from "@/axios";
import { Toast } from "vant";
import mitts from "@/bus";

const route = useRoute();
const router = useRouter();

const subdata = reactive({
  source: "",
  apply_id: 0,
  mb_id: 0,
  store_name: "",
  // person: "",
  // mobile: "",
  type: 0,
  device_type: 0,
  type_text: "",
  device_type_text: "",
  num: 0,
  line_handle_num: 0,
  blue_handle_num: 0,
  elabao_num: 0,
  lipai_num: 0,
  handle_stand_num: 0,
  remarks: "",
  imgs: "",
});

const storeNameplaceholder = ref("");
const storeNamePup = ref(false);
const buyTypePup = ref(false);
const equipTypePup = ref(false);
const storeNameList = ref([]);
const deviceTypeList = ref([]);
const buyTypeList = ref([]);
const storePic = ref([]);

const type_data =ref([])
const device_type_data =ref([])
const mb_data =ref([])

const isSelect = ref(true)

const buyTypeDef = ref("")
const deviceTypeDef = ref("")

function getinfo() {
  API({
    url: "/api/deviceApplyPage",
    method: "post",
    data: {
      apply_id: subdata.apply_id,
      source: subdata.source,
    },
  }).then((res) => {
    // console.log(res)
    mitts.emit("closeLoading");
    if (res.data.status == 200) {
      subdata.source = res.data.data.info.source;
      subdata.apply_id = res.data.data.info.apply_id;
      subdata.mb_id = res.data.data.info.mb_id;
 
      // subdata.person = res.data.data.info.person
      // subdata.mobile = res.data.data.info.mobile
      subdata.type = res.data.data.info.type
      subdata.device_type = res.data.data.info.device_type
      subdata.num = res.data.data.info.num
      subdata.line_handle_num = res.data.data.info.line_handle_num
      subdata.blue_handle_num = res.data.data.info.blue_handle_num
      subdata.elabao_num = res.data.data.info.elabao_num
      subdata.lipai_num = res.data.data.info.lipai_num
      subdata.handle_stand_num = res.data.data.info.handle_stand_num
      subdata.remarks = res.data.data.info.remarks
      subdata.imgs = res.data.data.info.imgs
      subdata.store_name = res.data.data.info.store_name;
      type_data.value = res.data.data.conf.buy_type
      device_type_data.value = res.data.data.conf.device_type
      mb_data.value = res.data.data.conf.mb_list

      for (let [index,item] of res.data.data.conf.buy_type.entries()) {
        if(item.val== subdata.type){
          subdata.type_text = item.title
          buyTypeDef.value = index
        }
      }

      for (let [index,item] of res.data.data.conf.device_type.entries()) {
        if(item.val== subdata.device_type){
          subdata.device_type_text = item.title
          deviceTypeDef.value = index
        }
      }

      let newNameList = [];
      for (const item of res.data.data.conf.mb_list) {
        newNameList.push(item.title);
      }
      storeNameList.value = newNameList;

      let newDeviceType = [];
      for (const item of res.data.data.conf.device_type) {
        newDeviceType.push(item.title);
      }
      deviceTypeList.value = newDeviceType;

      let newBuyType = [];
      for (const item of res.data.data.conf.buy_type) {
        newBuyType.push(item.title);
      }
      buyTypeList.value = newBuyType;

      //门店审核时申请设备，申请详情里的门店不能下拉选择
      if(res.data.data.info.store_aid !== undefined && res.data.data.info.store_aid > 0){
        isSelect.value = false;
      }

      if(res.data.data.info.imgs){
        storePic.value.push({
        url:res.data.data.info.imgs
      })
      }

      //总线传参赋值底部菜单
      mitts.emit('navs', res.data.data.main_nav);
      mitts.emit("toptitle", false);
      mitts.emit('navindex', 'business');
    } else {
      Toast.fail(res.data.msg);
      router.go(-1);
    }
  });
}

function storeNameClick() {
  storeNamePup.value = true;
}

function storeNameConfirm(val,index) {
  storeNamePup.value = false;
  subdata.store_name = val;
  subdata.mb_id = mb_data.value[index].val
}

function storeNameCancel() {
  storeNamePup.value = false;
}

function buyTypeClick() {
  buyTypePup.value = true;
}

function buyTypePupConfirm(val,index) {
  buyTypePup.value = false;
  subdata.type_text = val;
  subdata.type = type_data.value[index].val;
  
}

function buyTypePupCancel() {
  buyTypePup.value = false;
}

function equipTypeClick() {
  equipTypePup.value = true;
}

function equipTypeConfirm(val,index) {
  equipTypePup.value = false;
  subdata.device_type_text = val;
  subdata.device_type = device_type_data.value[index].val;
}

function equipTypeCancel() {
  equipTypePup.value = false;
}

function afterRead(file) {
  file.status = "uploading";
  file.message = "上传中";
  let form = new FormData();
  form.append("file", file.file);
  API({
    url: "/web/uploadImg",
    method: "post",
    data: form,
    timeout: 60000,
  }).then((res) => {
    file.url = res.data.url;
    file.status = "done";
    subdata.imgs = file.url;
  });
}

function subClick() {
  API({
    url: "/api/deviceApplySubmit",
    method: "post",
    data: {
      source: subdata.source,
      apply_id: subdata.apply_id,
      mb_id: subdata.mb_id,
      store_name: subdata.store_name,
      // person: subdata.person,
      // mobile: subdata.mobile,
      type: subdata.type,
      device_type: subdata.device_type,
      num: subdata.num,
      line_handle_num: subdata.line_handle_num,
      blue_handle_num: subdata.blue_handle_num,
      elabao_num: subdata.elabao_num,
      lipai_num: subdata.lipai_num,
      handle_stand_num: subdata.handle_stand_num,
      remarks: subdata.remarks,
      imgs: subdata.imgs,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      router.push({
        path: "/StoreManagement/done",
        query: {
          fromstatus: 1,
        },
      });
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

onMounted(() => {
  if(route.query.source == undefined){
    storeNameplaceholder.value = "请选择门店";
    subdata.source = "new"
    isSelect.value = true
  }else if(route.query.source == "edit") {
    storeNameplaceholder.value = "请选择门店";
    subdata.source = "edit"
    isSelect.value = true
  } else if(route.query.source == "store"){
    storeNameplaceholder.value = "请填写门店";
    subdata.source = "store"
    isSelect.value = false
  }else{
    storeNameplaceholder.value = "请选择门店";
    subdata.source = route.query.source
    isSelect.value = true
  }

  if(route.query.apply_id){
    subdata.apply_id = route.query.apply_id
  }else{
    subdata.apply_id = ""
  }

  mitts.emit("toptitle", false);

  setTimeout(()=>{
    getinfo();
  },500)
  
});
</script>

<style lang="less" scoped>
.applyEquipment {
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.contant {
  height: 10px;
  flex-grow: 1;
  overflow-y: auto;
}
.setinfo {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 30px;
  .title {
    font-size: @subtitle-size;
    line-height: @subtitle-size;
    color: @font-secondary-color;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .tit {
      margin-right: 20px;
      span {
        color: #f00;
      }
    }
    .inp {
      min-width: 0;
      flex: 1;
      display: flex;
      align-items: center;
      color: #999;
      justify-content: flex-end;
      input {
        width: 100%;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: none;
      }
    }
    .aftertext {
      font-weight: 600;
      color: #999;
    }
    .text {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      .top {
        .tit {
          margin-bottom: 10px;
          span {
            color: #f00;
          }
        }
        .example {
          border-radius: 10px;
          padding: 5px;
          background: none;
          border: 1px solid @theme-secondary-color;
          font-size: 24px;
          color: @theme-secondary-color;
        }
      }
      .tip {
        font-size: 28px;
        color: #999;
      }
    }
  }
}
.remark {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  .tit {
    margin-bottom: 20px;
    span {
      color: #f00;
    }
  }
  .textarea {
    width: 100%;
    textarea {
      width: 100%;
      padding: 5px 10px;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      box-sizing: border-box;
      resize: none;
      background: #f9f9f9;
    }
  }
}

.btns {
  display: flex;
  padding: 20px 20px 0 20px;
  margin-bottom: 20px;
  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }
  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
    flex: 2;
  }
}
</style>
